import { createStyles } from "@material-ui/core";

const authed = () => createStyles({
    container: {
        backgroundColor: '#F4F5F9',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    appBar: {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        height: 80,
        padding: '0 45px',
    },
    userContainer: {
        alignItems: 'center',
        display: 'flex',
        left: 45,
        position: 'absolute',
        zIndex: 1,
    },
    avatar: {
        backgroundColor: '#CDD1DD',
        color: '#1E2F4F',
        cursor: 'pointer',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
        height: 38,
        marginRight: 13,
        width: 38,
    },
    userName: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 700,
        marginTop: 5,
    },
    userCompany: {
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        marginTop: -5,
    },
    sitesContainer: {
      margin: 'auto',
    },
    loader: {
        color: '#3AB4FB',
    },
    logo: {
        cursor: 'pointer',
        position: 'absolute',
        right: 45,
        zIndex: 1,
        width: 130,
    },
    content: {
        display: 'flex',
        minHeight: 'calc(100% - 80px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        marginTop: 80,
    },
});

export default authed;
