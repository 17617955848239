import { createStyles } from "@material-ui/core";

const containersStillOnSite = () => createStyles({
    container: {
        borderRadius: 10,
        height: '100%',
    },
    actionArea: {
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    topContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '20px 28px',
        width: 'calc(100% - 28px - 28px)',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 30,
    },
    chartContainer: {
        flex: 1,
        position: "relative",
    },
    totalContainers: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        left: "50%",
        position: "absolute",
        top: "75%",
        transform: "translate(-50%, -50%)",
    },
    containersNumber: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 20,
        fontWeight: 700,
    },
    containersText: {
        color: '#828A8C',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 500,
    },
    legendContainer: {
        alignItems: 'center',
        borderColor: '#DADCE3',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 0',
    },
    sealedNumber: {
        color: '#60B690',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 20,
        fontWeight: 700,
    },
    notSealedNumber: {
        color: '#1C9195',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 20,
        fontWeight: 700,
    },
    legendText: {
        color: '#828A8C',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 500,
    },
});

export default containersStillOnSite;
