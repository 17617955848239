import React from "react";
import {Box, Card, CardActionArea, Grid, Typography, WithStyles} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";
import { Doughnut } from 'react-chartjs-2';

import {Statistic} from "../../store/site";

import style from './styles/containersStillOnSite';

interface ContainersStillOnSiteProps extends WithStyles<typeof style>, WithTranslation {
    onModuleClick: () => void;
    statistic: Statistic;
}

const doughnutOptions = {
    legend: { display: false },
    elements: {
        arc: {
            borderWidth: 0,
        },
    },
    cutoutPercentage: 80,
    circumference: Math.PI,
    rotation: Math.PI,
};

const ContainersStillOnSite = (props: ContainersStillOnSiteProps) => {
    const { classes, onModuleClick, statistic, t } = props;

    const data = {
        labels: [t('modules.sealed'), t('modules.notSealed')],
        datasets: [{ data: [statistic.sealed, statistic.unsealed], backgroundColor: [ '#60B690', '#1C9195' ] }],
    };

    return (
        <Card elevation={3} className={classes.container} onClick={onModuleClick}>
            <CardActionArea className={classes.actionArea}>
                <Box className={classes.topContainer}>
                    <Typography className={classes.title}>
                        {t('modules.containersStillOnSite')}
                    </Typography>
                    <Grid container justify="center">
                        <Grid item xs={9} className={classes.chartContainer}>
                            <Doughnut width={250} data={data} options={doughnutOptions} />
                            <div className={classes.totalContainers}>
                                <Typography className={classes.containersNumber}>
                                    {statistic.total}
                                </Typography>
                                <Typography className={classes.containersText}>
                                    {t('modules.containers')}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item xs={6} component={Box} borderTop={1}  borderRight={1} className={classes.legendContainer}>
                        <Typography className={classes.sealedNumber}>
                            {statistic.sealed}
                        </Typography>
                        <Typography className={classes.containersText}>
                            {t('modules.sealed')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} component={Box} borderTop={1} className={classes.legendContainer}>
                        <Typography className={classes.notSealedNumber}>
                            {statistic.unsealed}
                        </Typography>
                        <Typography className={classes.containersText}>
                            {t('modules.notSealed')}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(ContainersStillOnSite)
);
