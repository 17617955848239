import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";

// import "../locales/dates";
import { Language, translations} from '../locales';

i18n
    .use(initReactI18next)
    .init({
        resources: translations,
        lng: window.navigator.language,
        fallbackLng: Language.EN,
        interpolation: {
            escapeValue: false,
            format: (value, format, locale) => {
                if (value instanceof Date && locale) {
                    return moment(value).locale(locale).format(format);
                }

                return value;
            }

        },
    })
    .then(() => {});

export default i18n;
