import {createStyles} from "@material-ui/core";

const locationOnSite = () => createStyles({
    container: {
        borderRadius: 10,
        cursor: 'move',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 350,
        overflow: 'hidden',
        position: 'relative',
        zIndex:  1,
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        left: 0,
        padding: '0 28px 20px',
        position: 'absolute',
        top: 20,
        width: 'calc(100% - 28px - 28px)',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
    },
    goToContainersList: {
        backgroundColor: '#43A5FF',
        borderRadius: 10,
        color: 'white',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
        padding: '2px 10px 2px 15px',
        textTransform: 'none',
    },
    goToContainerListIcon: {
        marginLeft: 5,
    },
});

export default locationOnSite;
