import React from "react";
import {DataGrid} from "@material-ui/data-grid";
import {Pagination, PaginationItem} from "@material-ui/lab";
import {Card, withStyles, WithStyles} from "@material-ui/core";

import style from "./styles/containersGrid";

interface ContainersGridProps extends WithStyles<typeof style> {
    containers: any[];
    onContainerClick: (containerId: string) => void;
}

const ContainersGrid = (props: ContainersGridProps) => {
    const { classes, containers, onContainerClick } = props;

    return (
        <Card className={classes.tableContainer} elevation={3}>
            <div className={classes.tableContent}>
                <DataGrid
                    onRowClick={(params) => {
                        onContainerClick(containers[params.rowIndex].id);
                    }}
                    disableSelectionOnClick
                    disableColumnMenu
                    autoPageSize
                    components={{
                        Pagination: (params) => (
                            <Pagination
                                className={classes.pagination}
                                renderItem={(item) => (
                                    <PaginationItem className={classes.paginationItem} {...item} />
                                )}
                                siblingCount={0}
                                count={params.state.pagination.pageCount}
                                page={params.state.pagination.page + 1}
                                onChange={(event, value) => params.api.current.setPage(value - 1)}
                                size="small" />
                        ),
                    }}
                    headerHeight={50}
                    rowHeight={50}
                    className={classes.root}
                    rows={containers}
                    columns={[
                        { field: 'container', headerName: 'N° container', flex: 1 },
                        { field: 'company', headerName: 'Organization', flex: 1 },
                        { field: 'user', headerName: 'User', flex: 1 },
                        { field: 'firsEventDate', headerName: 'Date of first event', flex: 1 },
                        { field: 'lastEventDate', headerName: 'Date of last event', flex: 1 },
                        { field: 'lastEvent', headerName: 'Last event', flex: 1 },
                        { field: 'lastSite', headerName: 'Last site', flex: 1 },
                        { field: 'downTime', headerName: 'Down time (days)', flex: 1 },
                        { field: 'turnTime', headerName: 'Turn time (days)', flex: 1 },
                    ]} />
            </div>
        </Card>
    );
};

export default withStyles(style)(ContainersGrid);
