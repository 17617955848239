import React from "react";
import {Button, Card, Grid, Typography, WithStyles} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";

// import {EventType} from "../../store/events";
// import {getEventTypeStyle} from "../../utils/events.utils";

import style from './styles/lastContainersEvents';

interface LastContainersEventsProps extends WithStyles<typeof style>, WithTranslation {
    onEventClick: (eventName: string, eventType: any) => void;
}

const LastContainersEvents = (props: LastContainersEventsProps) => {
    const { classes, onEventClick, t } = props;

    const events: any[] = [];

    return (
        <Card elevation={3} className={classes.container}>
            <Typography className={classes.title}>
                {t('modules.lastContainersEvents')}
            </Typography>
            <Grid container spacing={2}>
                {events.map((event) => (
                    <Grid key={event.name} item xs={4}>
                        <Button
                            className={classes.eventButton}
                            onClick={() => {
                                onEventClick(event.name, event.type);
                            }}
                            // style={getEventTypeStyle(event.type)}
                            fullWidth>
                            <div>
                                <Typography className={classes.containerNumber}>
                                    {event.containers}
                                </Typography>
                                <Typography className={classes.eventType}>
                                    {event.name}
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(LastContainersEvents)
);
