import {arrayAdd, arrayRemove, Store, StoreConfig} from '@datorama/akita';

import {DashboardData, SitePermission, SiteProcessStep, Statistic} from "./site.model";

export function createInitialState(): DashboardData {
    return {
        process: [],
        permissions: [],
        containersStillOnSite: { sealed: 0, unsealed: 0, total: 0 },
    };
}

@StoreConfig({ name: 'dashboardData', resettable: true })
export class SiteStore extends Store<DashboardData> {
    constructor() {
        super(createInitialState());
    }

    updateSiteProcess = (process: SiteProcessStep[]) => {
        this.update({ process });
    }

    updatePermissions = (permissions: SitePermission[]) => {
        this.update({ permissions });
    }

    updateContainerStillOnSite = (containersStillOnSite: Statistic) => {
        this.update({ containersStillOnSite });
    }

    addPermission = (permission: SitePermission) => {
        this.update((state) => ({
            permissions: arrayAdd(state.permissions, permission),
        }));
    }

    removePermission = (userId: string) => {
        this.update((state) => ({
            permissions: arrayRemove(state.permissions, (p: SitePermission) => p.user.id === userId),
        }));
    }
}

export const siteStore = new SiteStore();
