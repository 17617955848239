import React from "react";
import OlMap from "ol/Map";
import OlView from "ol/View";
import {transform} from "ol/proj";
import * as Layer from "ol/layer";
import * as Source from "ol/source";
import {Coordinate} from "ol/coordinate";
import {withStyles, WithStyles} from "@material-ui/core";
import VectorLayer from "ol/layer/Vector";

import 'ol/ol.css';
import style from "./styles/map";

interface MapProps extends WithStyles<typeof style> {
    clusters?: VectorLayer;
}

interface MapState {
    center?: Coordinate;
    zoom?: number;
}

class Map extends React.Component<MapProps, MapState> {
    private olMap: OlMap;

    constructor(props: MapProps) {
        super(props);

        this.state = {
            center: transform([5.358848, 43.322057], 'EPSG:4326', 'EPSG:3857'),
            zoom: 16.5,
        };

        let layers: any[] = [
            new Layer.Tile({
                source: new Source.OSM(),
            })
        ];

        if (props.clusters) layers.push(props.clusters);

        this.olMap = new OlMap({
            target: undefined,
            layers,
            view: new OlView({
                center: this.state.center,
                zoom: this.state.zoom,
                enableRotation: false,
            }),
        });
    }

    componentDidMount() {
        this.olMap.setTarget("map");

        setTimeout(() => { this.olMap.updateSize(); }, 1);
    }

    render() {
        const { classes } = this.props;

        return (
            <div id="map" className={classes.map} />
        );
    }
}

export default withStyles(style)(Map);
