import React, {useState} from "react";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
    Box,
    Card,
    CardActionArea,
    IconButton,
    Step,
    StepLabel,
    Stepper,
    Typography,
    WithStyles
} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";

import {SiteProcessStep} from "../../store/site";

import {getEventTypeName} from "../../utils/events.utils";
import style from './styles/siteProcess';

interface SiteProcessProps extends WithStyles<typeof style>, WithTranslation {
    process: SiteProcessStep[];
    handleClick: () => void;
}

const SiteProcess = (props: SiteProcessProps) => {
    const { classes, handleClick, process, t } = props;

    const [firstIndex, setFirstIndex] = useState(0);

    let stepsToDisplay: (SiteProcessStep | null)[] = process.slice(firstIndex, firstIndex + 5);

    if (firstIndex + 5 < process.length) {
        stepsToDisplay.push(null);
    }

    return (
        <Card elevation={3} className={classes.container}>
            <div className={classes.arrowButtonsContainer}>
                <IconButton
                    className={classes.arrowButton}
                    disabled={!firstIndex}
                    onClick={() => setFirstIndex(firstIndex - 1)}
                    size="small">
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton
                    className={classes.arrowButton}
                    disabled={firstIndex + 5 >= process.length}
                    onClick={() =>setFirstIndex(firstIndex + 1)}
                    size="small">
                    <ChevronRightIcon />
                </IconButton>
            </div>
            <CardActionArea className={classes.actionArea} onClick={handleClick}>
                <Box className={classes.header}>
                    <Typography className={classes.title}>
                        {t('modules.siteProcess')}
                    </Typography>
                </Box>
                <Stepper activeStep={-1} className={classes.stepper}>
                    {stepsToDisplay.map((step) => (
                        <Step
                            key={step?.id || "more-steps"}
                            classes={{
                                root: classes.step,
                                completed: classes.completed,
                            }}>
                            {step && <StepLabel
                                classes={{
                                    label: classes.label,
                                    active: classes.label,
                                }}
                                icon={step.index}
                                StepIconProps={{
                                    classes: {
                                        root: classes.step,
                                        completed: classes.completed,
                                        active: classes.active,
                                    }
                                }}>
                                {step.label || getEventTypeName(step.type)}
                            </StepLabel>}
                        </Step>
                    ))}
                </Stepper>
            </CardActionArea>
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(SiteProcess)
);
