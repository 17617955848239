import React, {ChangeEvent, KeyboardEvent} from "react";
import {
    InputAdornment,
    InputBase,
    MenuItem, Paper,
    Select,
    TextField,
    WithStyles,
    withStyles
} from "@material-ui/core";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from '@material-ui/pickers';
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as style from './styles/textFields';

interface AuthTextFieldProps extends WithStyles<typeof style.authTextFields> {
    autoComplete?: string;
    fullWidth?: boolean;
    handleChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
    label?: string;
    placeholder?: string;
    name?: string;
    icon?: React.ReactNode;
    required?: boolean;
    type?: string;
    value?: string;
}

const AuthTextField = withStyles(style.authTextFields)((props: AuthTextFieldProps) => {
    const {
        autoComplete, classes, fullWidth = true, handleChange, handleKeyDown,
        icon, label, name, placeholder, required, type, value,
    } = props;

    return (
        <TextField
            className={classes.authTextField}
            variant="filled"
            margin="normal"
            required={required}
            fullWidth={fullWidth}
            label={label}
            hiddenLabel={!label}
            placeholder={placeholder}
            name={name}
            type={type}
            value={value}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            autoComplete={autoComplete}
            InputLabelProps={{
                classes: {
                    root: classes.authTextFieldLabel,
                    focused: classes.authTextFieldLabelFocused,
                },
            }}
            InputProps={{
                endAdornment: icon ? <InputAdornment position="end">{icon}</InputAdornment> : undefined,
                classes: {
                    root: classes.authTextFieldRoot,
                    underline: classes.authTextFieldUnderline,
                    focused: classes.focused,
                },
            }} />
    );
});

export enum SelectColor {
    GRAY,
    TRANSPARENT,
    DARK_GRAY,
}

interface SelectTextFieldProps extends WithStyles<typeof style.selectTextFields> {
    data: {
        value: string;
        label: string;
    }[];
    selectColor?: SelectColor;
    defaultLabel?: string;
    handleChange: (event: React.ChangeEvent<{value: unknown}>) => void;
    selected?: string;
}

const SelectTextField = withStyles(style.selectTextFields)((props: SelectTextFieldProps) => {
    const { classes, data, defaultLabel, handleChange, selectColor = SelectColor.GRAY, selected } = props;

    const getInput = () => {
        switch (selectColor) {
            case SelectColor.GRAY:
                return withStyles(style.grayInput)(InputBase);
            case SelectColor.TRANSPARENT:
                return withStyles(style.transparentInput)(InputBase);
            case SelectColor.DARK_GRAY:
                return withStyles(style.darkGrayInput)(InputBase);
        }
    };

    let Input = getInput();

    let value;
    if (defaultLabel)
        value = selected || "default";
    else if (selected)
        value = selected
    else {
        value = data.length ? data[0].value : "";
    }

    return (
        <Select
            classes={{
                icon: selectColor !== SelectColor.TRANSPARENT ? classes.iconBlack : classes.iconGray,
            }}
            MenuProps={{
                classes: { paper: classes.selectMenuPaper, list: classes.selectMenuList },
                getContentAnchorEl: null,
                anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }}
            disabled={!data.length}
            value={value}
            onChange={handleChange}
            input={<Input />}>
            {defaultLabel && <MenuItem
                classes={{
                    root: data.length > 0 ? classes.menuItem : classes.menuItemBordered,
                    selected: classes.menuItemSelected,
                }}
                value={"default"}>
                {defaultLabel}
            </MenuItem>}
            {data.map((d, index) => (
                <MenuItem
                    key={`${d.label} ${index}`}
                    classes={{
                        root: index === data.length - 1 ? classes.menuItem : classes.menuItemBordered,
                        selected: classes.menuItemSelected,
                    }}
                    value={d.value}>
                    {d.label}
                </MenuItem>
            ))}
        </Select>
   );
});

interface DateTextFieldProps extends WithStyles<typeof style.darkGrayTextField> {
    date?: Date;
    handleChange: (date: MaterialUiPickersDate) => void;
    label: string;
}

const DateTextField = withStyles(style.darkGrayTextField)((props: DateTextFieldProps) => {
    const { classes, date, handleChange, label } = props;

    return (
        <DatePicker
            label={label}
            format="DD MMM. YYYY"
            TextFieldComponent={(p) => (
                <TextField
                    {...p}
                    variant="filled"
                    InputLabelProps={{
                        classes: {
                            root: classes.label,
                            focused: classes.labelFocused,
                        },
                    }}
                    InputProps={{
                        endAdornment: <CalendarTodayIcon className={classes.inputIcon} />,
                        classes: {
                            root: classes.root,
                            focused: classes.focused,
                            input: classes.input,
                        },
                        readOnly: true,
                        disableUnderline: true,
                    }}/>
            )}
            onChange={handleChange}
            value={date} />
    );
});

interface AutoCompleteProps extends WithStyles<typeof style.autoComplete> {
    handleChange: (value: string) => void;
    options: string[];
    placeholder?: string;
    value: string;
}

const AutoComplete = withStyles(style.autoComplete)((props: AutoCompleteProps) => {
    const { classes, handleChange, options, placeholder, value } = props;

    return (
        <Autocomplete
            className={classes.container}
            value={value}
            onChange={(event: any, newValue: string | null) => {
                handleChange(newValue || "");
            }}
            freeSolo
            disableClearable
            classes={{
                option: classes.option,
                listbox: classes.listBox,
                paper: classes.paper,
            }}
            PaperComponent={(p) => (
                <Paper elevation={8} {...p} />
            )}
            renderInput={(p) => (
                <TextField
                    {...p}
                    variant="outlined"
                    hiddenLabel
                    placeholder={placeholder}
                    InputProps={{
                        ...p.InputProps,
                        classes: {
                            root: classes.root,
                            focused: classes.focused,
                            notchedOutline: classes.input,
                        },
                    }} />
            )}
            options={options} />
    );
});

export { AuthTextField, AutoComplete, DateTextField, SelectTextField };
