import React from "react";
import {withSnackbar, WithSnackbarProps} from "notistack";

import {sessionQuery, sessionService} from "../store/session";

import LaunchingScreen from "./LaunchingScreen/LaunchingScreen";
import Authed from "./Authed/Authed";

import i18n from "../utils/i18n.utils";
import keycloak from "../utils/keycloak.utils";
import { updateRequestHeader } from "../utils/axios.utils";

interface RoutesProps extends WithSnackbarProps {}

interface RoutesState {
    isAuthenticated: boolean;
}

class Routes extends React.Component<RoutesProps, RoutesState> {
    constructor(props: RoutesProps) {
        super(props);

        this.state = {
            isAuthenticated: false,
        };
    }

    componentDidMount() {
        const { enqueueSnackbar } = this.props;

        keycloak.init({onLoad: 'login-required', checkLoginIframe: false }).then((authenticated: boolean) => {
            if (authenticated) {
                updateRequestHeader(keycloak.token)

                sessionService.fetchUser().subscribe(
                  () => {},
                  (err) => { enqueueSnackbar(i18n.t(err.key), { variant: err.variant}); },
                );
            } else {
                window.location.reload();
            }
        })
        .catch((err) => console.log(err));

        sessionQuery.isAuthenticated$.subscribe((isAuthenticated) => {
            this.setState({ isAuthenticated });
        });
    }

    render() {
        const { isAuthenticated } = this.state;

        if (!isAuthenticated) {
            return <LaunchingScreen />;
        }

        return <Authed />;
    }
}

export default withSnackbar(Routes);
