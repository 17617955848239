import React from "react";
import {Button, Card, CardHeader, withStyles, WithStyles} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {withTranslation, WithTranslation} from "react-i18next";
import {Feature} from "ol";
import {Point} from "ol/geom";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Fill, Style, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";
import {Cluster} from "ol/source";
import {transform} from "ol/proj";

// import {EventType} from "../../store/events";

import Map from "../Map";

import style from "./styles/locationOnSite";

const containers: any[] = [
    // { type: EventType.SEAL_IN, lastLocation: new firebase.firestore.GeoPoint(43.320974782822574, 5.359734782441228) },
    // { type: EventType.GATE_IN, lastLocation: new firebase.firestore.GeoPoint(43.32190988239833, 5.359037254768726) },
    // { type: EventType.GATE_IN, lastLocation: new firebase.firestore.GeoPoint(43.32190988239833, 5.359037254768726) },
    // { type: EventType.GATE_IN, lastLocation: new firebase.firestore.GeoPoint(43.32190988239833, 5.359037254768726) },
    // { type: EventType.GATE_IN, lastLocation: new firebase.firestore.GeoPoint(43.32190988239833, 5.359037254768726) },
    // { type: EventType.GATE_IN, lastLocation: new firebase.firestore.GeoPoint(43.32190988239833, 5.359037254768726) },
    // { type: EventType.UNSEALING, lastLocation: new firebase.firestore.GeoPoint(43.321738828673446, 5.356309843195177) },
];

interface LocationOnSiteProps extends WithTranslation, WithStyles<typeof style> {
    onModuleClick?: () => void;
}

const LocationOnSite = (props: LocationOnSiteProps) => {
    const { classes, onModuleClick, t } = props;

    const features = containers.map(({lastLocation}) => {
        const coordinates = transform([lastLocation.longitude, lastLocation.latitude], 'EPSG:4326', 'EPSG:3857');

        return new Feature(new Point(coordinates));
    });

    const source = new VectorSource({
        features,
    });

    const clusterSource = new Cluster({
        distance: parseInt("50", 10),
        source,
    })

    const clusters = new VectorLayer({
        source: clusterSource,
        style: (feature) => {
            let size = feature.get('features').length;

            return new Style({
                image: new CircleStyle({
                    radius: 10 + (10 * (size - 1) / 2),
                    fill: new Fill({
                        color: '#43A5FF',
                    }),
                }),
                text: new Text({
                    text: size > 1 ? size.toString() : null,
                    fill: new Fill({
                        color: 'white',
                    }),
                    scale: (size - 1) / 2,
                }),
            });
        },
    });

    return (
        <Card elevation={3} className={classes.container} onClick={() => {}}>
            <Map clusters={clusters} />
            <CardHeader
                className={classes.header}
                classes={{
                    title: classes.title,
                }}
                action={(
                    onModuleClick ? (
                        <Button
                            onClick={() => {
                                onModuleClick();
                            }}
                            className={classes.goToContainersList}>
                            Go to containers list
                            <ArrowRightAltIcon className={classes.goToContainerListIcon} />
                        </Button>
                    ) : null
                )}
                title={onModuleClick ? t("modules.locationOnSite") : undefined} />
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(LocationOnSite)
);
