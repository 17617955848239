import {User} from "../session";
import {EventType} from "../events";

export interface Container {
    id: string;
    arrivalOnSite: string;
    containerBooking: string;
    containerNumber: string;
    containerSealNumber: string;
    containerTare: string;
    containerType: string;
    lastEventTemplateID: string;
    lastLocation: Location;
    site: string;
    timeLaps: number;
}

export interface Statistic {
    sealed: number;
    unsealed: number;
    total: number;
}

export interface SitePermission {
    role: Role,
    user: User,
}

export interface SiteProcessStep {
    id: string;
    label: string;
    type: EventType;
    index: number;
}

export interface DashboardData {
    process: SiteProcessStep[];
    permissions: SitePermission[];
    containersStillOnSite: Statistic;
}

export enum Role {
    SITE_OWNER = "SITE_OWNER",
    ADMIN = "ADMIN",
    VIEWER = "VIEWER"
}

export const roles = [ Role.ADMIN, Role.VIEWER ];

export interface Location {
    latitude: number;
    longitude: number;
}

export interface UserSite {
    id: string;
    name: string;
    role: Role;
    location: Location;
}
