import {ModuleType} from "../store/session";
import React from "react";

export const getModuleImage = (moduleType: ModuleType) => {
    switch (moduleType) {
        case ModuleType.CONTAINERS_STILL_ON_SITE:
            return "images/dashboard/graph_still_onsite.png";
        case ModuleType.LOCATION_ON_SITE:
            return "images/dashboard/graph_map.png";
        case ModuleType.LAST_CONTAINERS_EVENTS:
            return "images/dashboard/graph_events.png";
        case ModuleType.TIME_LAPSE_SINCE_ARRIVAL_ON_SITE:
            return "images/dashboard/graph_time_lapse.png";
        case ModuleType.TURN_TIME_OF_EACH_EVENT:
            return "images/dashboard/graph_time_events.png";
        case ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL:
            return "images/dashboard/graph_truck.png";
        case ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL:
            return "images/dashboard/graph_chassis.png";
    }
}

export const getModuleName = (moduleType: ModuleType) => {
    switch (moduleType) {
        case ModuleType.CONTAINERS_STILL_ON_SITE:
            return "modules.containersStillOnSite";
        case ModuleType.LOCATION_ON_SITE:
            return "modules.locationOnSite";
        case ModuleType.LAST_CONTAINERS_EVENTS:
            return "modules.lastContainersEvents";
        case ModuleType.TIME_LAPSE_SINCE_ARRIVAL_ON_SITE:
            return "modules.timeLapseSinceArrivalOnSite";
        case ModuleType.TURN_TIME_OF_EACH_EVENT:
            return "modules.turnTimeOfEachEvent";
        case ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL:
            return "modules.truckTimeLapseSinceArrival";
        case ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL:
            return "modules.chassisTimeLapseSinceArrival";
    }
}

export const getModuleWeight = (moduleType: ModuleType) => {
    return moduleType === ModuleType.LOCATION_ON_SITE ? 2 : 1
}

export interface Module {
    type: ModuleType;
    position: number;
    isSelected: boolean;
    weight: number;
    render: () => React.ReactNode;
}

export const getModulesRows = (modules: Module[]): Module[][] => {
    return modules.reduce((arr, currentModule) => {
        let lastRowIndex = arr.length - 1;

        let currentRow = lastRowIndex !== -1 ? arr[lastRowIndex] : [];

        const rowWeight = currentRow.reduce((w, m) => w + m.weight, currentModule.weight);

        if (lastRowIndex === -1 || rowWeight > 3) {
            arr.push([currentModule]);
        } else {
            currentRow.push(currentModule);
            arr[lastRowIndex] = currentRow;
        }

        return arr;
    }, [] as Module[][]);
}
