import {Query} from '@datorama/akita';

import {DashboardData} from "./site.model";
import {SiteStore, siteStore} from "./site.store";

export class SiteQuery extends Query<DashboardData> {

    get dashboardData() { return this.getValue(); }

    constructor(protected store: SiteStore) {
        super(store);
    }

    dashboardData$ = this.select();
}

export const siteQuery = new SiteQuery(siteStore);
