import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Grid, IconButton, Typography, withStyles, WithStyles} from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";

import {getModuleName} from "../../../utils/modules.utils";
import {ModuleType} from "../../../store/session";

import {AutoComplete, DateTextField} from "../../../components/TextFields";
import ContainersGrid from "../../../components/containers/ContainersGrid";
import {LocationOnSite} from "../../../components/dashboard";

import style from "./styles/containers";

interface ContainersProps extends RouteComponentProps, WithStyles<typeof style>, WithTranslation {}

interface ContainersState {
    filters: {
        fromDate: Date;
        toDate: Date;
        search: string;
    };
}

class Containers extends React.Component<ContainersProps, ContainersState> {
    constructor(props: ContainersProps) {
        super(props);

        this.state = {
            filters: {
                fromDate: new Date(),
                toDate: new Date(),
                search: "",
            },
        };
    }

    handleFilterChange = (name: string) => (value: any) => {
        const { filters } = this.state;
        this.setState({ filters: { ...filters, [name]: value } })
    }

    render() {
        const { classes, history, t } = this.props;

        const { filters } = this.state;

        const { moduleType, eventName } = history.location.state as any || {}

        return (
            <div className={classes.container}>
                <Grid container className={classes.gridContent} spacing={3} alignContent="flex-start">
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            {t(getModuleName(moduleType))}{moduleType === ModuleType.LAST_CONTAINERS_EVENTS && ` - ${eventName}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.searchContainer}>
                        <div className={classes.dateContainer}>
                            <DateTextField
                                date={filters.fromDate}
                                handleChange={(date) => {
                                    this.handleFilterChange("fromDate")(date?.toDate() || new Date());
                                }}
                                label={t("input.dateFrom")} />
                        </div>
                        <div className={classes.dateContainer}>
                            <DateTextField
                                date={filters.toDate}
                                handleChange={(date) => {
                                    this.handleFilterChange("toDate")(date?.toDate() || new Date());
                                }}
                                label={t("input.dateTo")} />
                        </div>
                        <div className={classes.selectContainer}>
                            <Typography className={classes.containerNumber}>
                                {t("containers.containerNumber")}
                            </Typography>
                            <div className={classes.selectDivider} />
                            <AutoComplete
                                handleChange={this.handleFilterChange("search")}
                                value={filters.search}
                                placeholder={t("input.search")}
                                options={["Ar", "Aaaa"]} />
                        </div>
                    </Grid>
                    <Grid container item xs={1} alignItems="center">
                        <div className={classes.divider} />
                    </Grid>
                    <Grid item xs={3} className={classes.actionsContainer}>
                        <IconButton className={classes.exportDataButton}>
                            <img src="/images/dashboard/icon_export_data.svg" alt="" />
                        </IconButton>
                    </Grid>
                    {moduleType === ModuleType.LOCATION_ON_SITE && (
                        <Grid item xs={12}>
                            <LocationOnSite />
                        </Grid>
                    )}
                </Grid>
                <ContainersGrid
                    containers={[
                        { id: "1", container: 'Hello', company: 'World', user: 'World', firsEventDate: 'World', lastEventDate: 'World', lastEvent: 'World', lastSite: 'World', turnTime: 'World', downTime: 'World' },
                        { id: "2", container: 'Hello', company: 'World', user: 'World', firsEventDate: 'World', lastEventDate: 'World', lastEvent: 'World', lastSite: 'World', turnTime: 'World', downTime: 'World' },
                        { id: "3", container: 'Hello', company: 'World', user: 'World', firsEventDate: 'World', lastEventDate: 'World', lastEvent: 'World', lastSite: 'World', turnTime: 'World', downTime: 'World' },
                        { id: "4", container: 'Hello', company: 'World', user: 'World', firsEventDate: 'World', lastEventDate: 'World', lastEvent: 'World', lastSite: 'World', turnTime: 'World', downTime: 'World' },
                    ]}
                    onContainerClick={(_) => {
                       // history.push("/container", { containerId });
                    }} />
            </div>
        );
    }
}

export default withStyles(style)(
    withTranslation()(withRouter(Containers))
);

