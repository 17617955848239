import {from, Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {applyTransaction, withTransaction} from "@datorama/akita";
import {AxiosResponse} from "axios";

import {SessionStore, sessionStore} from './session.store';
import {sessionQuery} from "./session.query";
import {CurrentUser, ModuleType} from "./session.model";

import SnackError from "../../utils/error.utils";
import keycloak from "../../utils/keycloak.utils";
import axios, {APIRoutes} from "../../utils/axios.utils";

export class SessionService {
    constructor(private store: SessionStore) {}

    fetchUser = (): Observable<CurrentUser> =>
       from(axios({...APIRoutes.currentUser()}))
            .pipe(
                catchError(() => {
                    this.logOut();

                    throw new SnackError("errors.serverError", "error");
                }),
                map((response: AxiosResponse<CurrentUser>) => {
                    let user = response.data;

                    user.organizations = user.organizations.filter((company) => company.sites.length);

                    if (!user.organizations.length) {
                        this.logOut();

                        throw new SnackError("errors.serverError", "error");
                    }

                    return user;
                }),
                withTransaction((user: CurrentUser) => {
                    this.store.initUserConfiguration(user);

                    this.store.updateUser(user);
                }),
            );

    logOut = (): void => {
        keycloak.logout();
    }

    setCurrentCompany = (companyId: string): void =>
        applyTransaction(() => {
            this.store.setCurrentCompanyId(companyId);

            const company = sessionQuery.userCompanies.find((company) => company.id === companyId)!;
            this.setCurrentSite(company.sites[0].id);
        });

    setCurrentSite = (siteId: string): void => this.store.setCurrentSiteId(siteId);

    saveModulesOrganization = (modulesOrganization: ModuleType[]) => {
        this.store.updateModulesOrganization(modulesOrganization);
    }
}

export const sessionService = new SessionService(sessionStore);
