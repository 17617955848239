import React from "react";
import {Grid, IconButton, WithStyles, withStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {withSnackbar, WithSnackbarProps} from "notistack";
import {Subscription} from "rxjs";

import {ModuleType, sessionQuery} from "../../../store/session";
import {DashboardData, Role, siteQuery, siteService, UserSite} from "../../../store/site";

import {AutoComplete, DateTextField, SelectColor, SelectTextField} from "../../../components/TextFields";
import { DashboardPermissionsCard } from "../../../components/PermissionsCards";
import {
    SiteProcess,
    ContainersStillOnSite,
    LastContainersEvents,
    TimeLapseSinceArrivalOnSite,
    TruckTimeLapseSinceArrival,
    ManageModules,
    ChassisTimeLapseSinceArrival,
    ManagePermissions,
    LocationOnSite,
    TurnTimeOfEachEvent,
} from '../../../components/dashboard';

import style from './styles/dashboard';

enum OpenModalState {
    NONE,
    MANAGE_MODULES,
    MANAGE_PERMISSIONS,
}

enum SearchTypes {
    containerNumber = "dashboard.containerNumber",
    bookingNumber = "dashboard.bookingNumber",
    truckNumber = "dashboard.truckNumber",
    frameNumber = "dashboard.frameNumber",
}

const mapSearchTypesForSelect = (t: any) => Object.keys(SearchTypes).map((k) => ({
    value: (SearchTypes as any)[k],
    label: t((SearchTypes as any)[k]),
}));

interface DashboardProps extends RouteComponentProps, WithStyles<typeof style>, WithTranslation, WithSnackbarProps {}

interface DashboardState {
    filters: {
        fromDate?: Date;
        toDate?: Date;
        searchType: SearchTypes;
        search: string;
    };
    openModalState: OpenModalState;

    site: UserSite;
    modulesOrganization: ModuleType[];

    dashboardData: DashboardData;
}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
    private _currentSite?: Subscription = undefined;
    private _modulesOrganization?: Subscription = undefined;
    private _getDashboardData?: Subscription = undefined;
    private _dashboardData?: Subscription = undefined;

    constructor(props: DashboardProps) {
        super(props);

        this.state = {
            filters: {
                fromDate: undefined,
                toDate: undefined,
                searchType: SearchTypes.containerNumber,
                search: "",
            },
            openModalState: OpenModalState.NONE,

            site: sessionQuery.currentSite,
            modulesOrganization: [],

            dashboardData: siteQuery.dashboardData,
        };
    }

    componentDidMount() {
        const { enqueueSnackbar, t } = this.props;

        this._currentSite = sessionQuery.currentSite$.subscribe((site) => {
            this.setState({ site });
        });
        this._modulesOrganization = sessionQuery.modulesOrganizationForSite$.subscribe((modulesOrganization) => {
            this.setState({ modulesOrganization });
        });

        this._getDashboardData = siteService.fetchDashboardData().subscribe(
            () => {},
            (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
        )
        this._dashboardData = siteQuery.dashboardData$.subscribe((dashboardData) => {
            this.setState({ dashboardData });
        });
    }

    componentWillUnmount() {
        if (this._currentSite) this._currentSite.unsubscribe();
        if (this._modulesOrganization) this._modulesOrganization.unsubscribe();
        if (this._getDashboardData) this._getDashboardData.unsubscribe();
        if (this._dashboardData) this._dashboardData.unsubscribe();
    }

    handleOpenModalState = (openModalState: OpenModalState) => {
        this.setState({ openModalState });
    }

    renderModal = () => {
        const { dashboardData, modulesOrganization, openModalState } = this.state;

        switch (openModalState) {
            case OpenModalState.MANAGE_MODULES:
                return (
                    <ManageModules
                        modulesOrganization={modulesOrganization}
                        handleClose={() => {
                            this.handleOpenModalState(OpenModalState.NONE);
                        }} />
                );
            case OpenModalState.MANAGE_PERMISSIONS:
                return (
                    <ManagePermissions
                        permissions={dashboardData?.permissions || []}
                        handleClose={() => {
                            this.handleOpenModalState(OpenModalState.NONE);
                        }} />
                );
            default:
                return null;
        }
    }

    handleFilterChange = (name: string) => (value: any) => {
        const { filters } = this.state;
        this.setState({ filters: { ...filters, [name]: value } })
    }

    renderModule = (moduleType: ModuleType) => {
        const { history } = this.props;

        const { dashboardData } = this.state;

        switch (moduleType) {
            case ModuleType.CONTAINERS_STILL_ON_SITE:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <ContainersStillOnSite
                            statistic={dashboardData.containersStillOnSite}
                            onModuleClick={() => {
                                history.push("/containers", { moduleType });
                            }} />
                    </Grid>
                );
            case ModuleType.LOCATION_ON_SITE:
                return (
                    <Grid item xs={8} key={`dashboard-${moduleType}`}>
                        <LocationOnSite
                            onModuleClick={() => {
                                history.push("/containers", { moduleType });
                            }} />
                    </Grid>
                );
            case ModuleType.LAST_CONTAINERS_EVENTS:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <LastContainersEvents
                            onEventClick={(eventName, eventType) => {
                                history.push("/containers", { moduleType, eventName, eventType });
                            }} />
                    </Grid>
                );
            case ModuleType.TIME_LAPSE_SINCE_ARRIVAL_ON_SITE:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <TimeLapseSinceArrivalOnSite
                            onModuleClick={() => {
                                history.push("/containers", { moduleType });
                            }} />
                    </Grid>
                );
            case ModuleType.TURN_TIME_OF_EACH_EVENT:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <TurnTimeOfEachEvent
                            onModuleClick={() => {
                                history.push("/containers", { moduleType });
                            }} />
                    </Grid>
                );
            case ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <TruckTimeLapseSinceArrival
                            onModuleClick={() => {
                                history.push("/containers", { moduleType });
                            }} />
                    </Grid>
                );
            case ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <ChassisTimeLapseSinceArrival
                            onModuleClick={() => {
                                history.push("/containers", { moduleType });
                            }} />
                    </Grid>
                );
        }
    }

    render() {
        const { classes, history, t } = this.props;

        const { dashboardData, filters, modulesOrganization, site } = this.state;

        return (
            <div className={classes.container}>
                <Grid container className={classes.gridContent} spacing={3} alignItems="stretch">
                    {site.role !== Role.VIEWER && (
                        <Grid item xs={9}>
                            <SiteProcess
                                process={dashboardData?.process || []}
                                handleClick={() => { history.push("/events"); }} />
                        </Grid>
                    )}
                    {site.role !== Role.VIEWER && (
                        <Grid item xs={3}>
                            <DashboardPermissionsCard
                                permissions={dashboardData?.permissions || []}
                                onPermissionsClick={() => {
                                    this.handleOpenModalState(OpenModalState.MANAGE_PERMISSIONS);
                                }} />
                        </Grid>
                    )}
                    <Grid item xs={8} className={classes.searchContainer}>
                        <div className={classes.dateContainer}>
                            <DateTextField
                              date={filters.fromDate}
                              handleChange={(date) => {
                                  this.handleFilterChange("fromDate")(date?.toDate() || new Date());
                              }}
                              label={t("input.dateFrom")} />
                        </div>
                        <div className={classes.dateContainer}>
                            <DateTextField
                              date={filters.toDate}
                              handleChange={(date) => {
                                  this.handleFilterChange("toDate")(date?.toDate() || new Date());
                              }}
                              label={t("input.dateTo")} />
                        </div>
                        <div className={classes.selectContainer}>
                            <SelectTextField
                              selected={filters.searchType}
                              selectColor={SelectColor.DARK_GRAY}
                              handleChange={(event) => {
                                  this.handleFilterChange("searchType")(event.target.value);
                              }}
                              data={mapSearchTypesForSelect(t)} />
                            <div className={classes.divider} />
                            <AutoComplete
                              handleChange={this.handleFilterChange("search")}
                              value={filters.search}
                              placeholder={t("input.search")}
                              options={["Ar", "Aaaa"]} />
                        </div>
                    </Grid>
                    <Grid item xs={4} className={classes.actionsContainer}>
                        <IconButton className={classes.exportDataButton}>
                            <img src="/images/dashboard/icon_export_data.svg" alt="" />
                        </IconButton>
                        <IconButton
                          className={classes.editButton}
                          onClick={() => {
                              this.handleOpenModalState(OpenModalState.MANAGE_MODULES);
                          }}>
                            <img src="/images/dashboard/icon_edit.svg" alt="" />
                        </IconButton>
                    </Grid>
                    {modulesOrganization.map(this.renderModule)}
                </Grid>
                {this.renderModal()}
            </div>
        );
    }
}

export default withStyles(style)(
    withTranslation()(
        withRouter(withSnackbar(Dashboard))
    )
);
