import {forkJoin, from, Observable} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {AxiosResponse} from "axios";

import {sessionQuery} from "../session";
import {Container, DashboardData, SitePermission, SiteProcessStep} from "./site.model";
import {siteStore, SiteStore} from "./site.store";

import axios, {APIRoutes} from "../../utils/axios.utils";
import SnackError from "../../utils/error.utils";
import {withTransaction} from "@datorama/akita";
import {siteQuery} from "./site.query";
import {EventTemplate} from "../events";

export class SiteService {
		constructor(private store: SiteStore) {}

		fetchSiteProcess = (): Observable<SiteProcessStep[]> =>
				from(axios({...APIRoutes.eventTemplates(sessionQuery.currentSiteId)}))
						.pipe(
								catchError((err) => {
										switch (err.response?.data?.errorMessage) {
												case "SITE_NOT_FOUND":
														throw new SnackError("errors.siteNotFound", "error");
												default:
														throw new SnackError("errors.serverError", "error");
										}
								}),
								map((response: AxiosResponse<EventTemplate[]>) => {
										return response.data.map((event, index) => ({
												id: event.id,
												label: event.label,
												type: event.type,
												index: index + 1,
										}));
								}),
						);

		fetchSitePermissions = (): Observable<SitePermission[]> =>
				from(axios({...APIRoutes.sitePermissions(sessionQuery.currentSiteId)}))
						.pipe(
								catchError((err) => {
										switch (err.response?.data?.errorMessage) {
												case "SITE_NOT_FOUND":
														throw new SnackError("errors.siteNotFound", "error");
												default:
														throw new SnackError("errors.serverError", "error");
										}
								}),
								map((response: AxiosResponse<SitePermission[]>) => {
										return response.data;
								}),
						);

		fetchContainersStillOnSite = (): Observable<any> =>
				from(axios({
						...APIRoutes.containersStillOnSite(),
						params: { site: sessionQuery.currentSiteId },
				}))
						.pipe(
								catchError((err) => {
										console.log(err.reesponse?.data);
										switch (err.response?.data?.errorMessage) {
												case "SITE_NOT_FOUND":
														throw new SnackError("errors.siteNotFound", "error");
												default:
														throw new SnackError("errors.serverError", "error");
										}
								}),
								map((response: AxiosResponse<SitePermission[]>) => {
										return response.data;
								}),
						);

		fetchDashboardData = (): Observable<DashboardData> => {
				const dashboardObservables = [
						this.fetchSiteProcess(),
						this.fetchSitePermissions(),
						// this.fetchContainersStillOnSite(),
				];

				return sessionQuery.currentIds$
						.pipe(
								switchMap(() => {
										return forkJoin([...dashboardObservables])
								}),
								withTransaction((
									[ process, permissions/*, containerStillOnSite*/ ]
								) => {
										this.store.updateSiteProcess(process as SiteProcessStep[]);
										this.store.updatePermissions(permissions as SitePermission[]);
										// this.store.updateContainerStillOnSite(containerStillOnSite as Statistic);
								}),
								map(() => {
										return siteQuery.dashboardData;
								}),
						);
		}

		addSitePermission = (email: string, role: string): Observable<string | undefined> =>
				from(axios({
						...APIRoutes.addSitePermission(sessionQuery.currentSiteId),
						data: { email, role },
				}))
						.pipe(
								catchError((err) => {
										console.log(err.response);
										switch (err.response?.data?.errorMessage) {
												case "SITE_PERMISSION_ALREADY_EXISTS":
														throw new SnackError("warning.sitePermissionAlreadyExists", "warning");
												case "SITE_NOT_FOUND":
														throw new SnackError("errors.siteNotFound", "error");
												default:
														throw new SnackError("errors.serverError", "error");
										}
								}),
								map((response: AxiosResponse<SitePermission | undefined>) => {
										console.log(response);
										return response.data;
								}),
								map((permission?: SitePermission) => {
										if (permission) {
												this.store.addPermission(permission);
										} else {
												return "success.mailSent";
										}
								}),
						);

		removeSitePermission = (userId: string): Observable<AxiosResponse> =>
				from(axios({...APIRoutes.removeSitePermission(sessionQuery.currentSiteId, userId)}))
						.pipe(
								catchError((err) => {
										console.log(err.response);
										switch (err.response?.data?.errorMessage) {
												case "CANNOT_DELETE_ADMIN_OF_SITE":
														throw new SnackError("warning.cannotDeleteAdminOfSite", "warning");
												case "SITE_NOT_FOUND":
														throw new SnackError("errors.siteNotFound", "error");
												case "SITE_PERMISSION_NOT_FOUND":
														throw new SnackError("errors.sitePermissionNotFound", "error");
												case "USER_NOT_FOUND":
														throw new SnackError("errors.userNotFound", "error");
												default:
														throw new SnackError("errors.serverError", "error");
										}
								}),
								tap(() => {
										this.store.removePermission(userId);
								}),
						);

		fetchContainers = (): Observable<Container[]> =>
				from(axios({...APIRoutes.containers(sessionQuery.currentSiteId)}))
						.pipe(
								catchError((err) => {
										console.log(err.reesponse?.data);
										switch (err.response?.data?.errorMessage) {
												case "SITE_NOT_FOUND":
														throw new SnackError("errors.siteNotFound", "error");
												default:
														throw new SnackError("errors.serverError", "error");
										}
								}),
								map((response: AxiosResponse<Container[]>) => {
										return response.data;
								}),
						);
}

export const siteService = new SiteService(siteStore);
