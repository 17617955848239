import React from "react";
import {Card, CardActionArea, Typography, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {Bubble} from "react-chartjs-2";

// import {EventType} from "../../store/events";
// import {getEventTypeName} from "../../utils/events.utils";

import style from "./styles/turnTimeOfEachEvent";

interface TurnTimeOfEachEventProps extends WithStyles<typeof style>, WithTranslation {
    onModuleClick: () => void;
}

const bubbleOptions = {
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            type: 'category',
            offset: true,
            gridLines: {
                drawTicks: false,
                zeroLineWidth: 0,
                offsetGridLines: true,
            },
            ticks: {
                padding: 10,
            },
        }],
        yAxes: [{
            gridLines: {
                drawTicks: false,
                zeroLineWidth: 0,
                lineWidth: [1, 0, 0, 0, 1]
            },
            ticks: {
                beginAtZero: true,
                stepSize: 20,
                max: 80,
                padding: 10,
            },
        }],
    },
};

const TurnTimeOfEachEvent = (props: TurnTimeOfEachEventProps) => {
    const { classes, onModuleClick, t } = props;

    const events: any[] = [
        // { name:  getEventTypeName(EventType.GATE_IN), turnTime: 22 },
        // { name:  getEventTypeName(EventType.UNSEALING), turnTime: 36 },
        // { name:  getEventTypeName(EventType.SEAL_IN), turnTime: 10 },
        // { name:  getEventTypeName(EventType.WEIGHTING), turnTime: 48 },
        // { name:  getEventTypeName(EventType.STUFFING), turnTime: 32 },
    ];

    return (
        <Card elevation={3} className={classes.container} onClick={onModuleClick}>
            <CardActionArea className={classes.actionArea}>
                <Typography className={classes.title}>
                    {t('modules.turnTimeOfEachEvent')}
                </Typography>
                <Bubble
                    options={bubbleOptions}
                    data={{
                        labels: events.map((evt) => evt.name),
                        datasets: [{
                            backgroundColor: "#43A5FF",
                            radius: 6,
                            data: events.map((evt) => evt.turnTime),
                        }],
                    }} />
            </CardActionArea>
        </Card>
    )
}

export default withStyles(style)(
    withTranslation()(TurnTimeOfEachEvent)
);
