import React from "react";
import {Card, CardActionArea, Typography, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {Bar} from "react-chartjs-2";
import moment from "moment";

import style from "./styles/chassisTimeLapseSinceArrival";

const sealedContainers = [
    { arrivalOnSite: new Date("Mon Mar 01 2021") },
    { arrivalOnSite: new Date("Tue Mar 09 2021") },
    { arrivalOnSite: new Date("Sat Feb 13 2021") },
    { arrivalOnSite: new Date("Mon Feb 01 2021") },
    { arrivalOnSite: new Date("Mon Feb 01 2021") },
    { arrivalOnSite: new Date("Mon Feb 01 2021") },
    { arrivalOnSite: new Date("Mon Feb 01 2021") },
    { arrivalOnSite: new Date("Mon Feb 01 2021") },
];

const unsealedContainers = [
    { arrivalOnSite: new Date("Mon Mar 01 2021") },
    { arrivalOnSite: new Date("Mon Mar 01 2021") },
    { arrivalOnSite: new Date("Mon Mar 01 2021") },
    { arrivalOnSite: new Date("Mon Mar 01 2021") },
    { arrivalOnSite: new Date("Wed Mar 10 2021") },
    { arrivalOnSite: new Date("Sat Feb 13 2021") },
    { arrivalOnSite: new Date("Mon Feb 01 2021") },
];

interface ChassisTimeLapseSinceArrivalProps extends WithStyles<typeof style>, WithTranslation {
    onModuleClick: () => void;
}

const barOptions = {
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            offset: true,
            stacked: true,
            gridLines: {
                drawTicks: false,
                drawOnChartArea: false,
            },
            ticks: {
                // Hide first and last tick label
                callback: (value: any, index: number) => {
                    if (index % 5 !== 0 || index === 0) {
                        return undefined;
                    }

                    return value;
                },
                maxRotation: 0,
                padding: 10,
            },
        }],
        yAxes: [{
            type: 'linear',
            ticks: {
                beginAtZero: true,
                suggestedMax: 10,
            },
            display: false,
        }],
    },
};

const ChassisTimeLapseSinceArrival = (props: ChassisTimeLapseSinceArrivalProps) => {
    const { classes, onModuleClick, t } = props;

    const labels = Array.from({length: 50}, (i,index) => index);

    const mappedSealedContainers = sealedContainers.reduce((arr, container) => {
        const daysSinceArrival = moment().diff(moment(container.arrivalOnSite), 'd');

        arr[daysSinceArrival] += 1

        return arr;
    }, labels.map(() => 0));


    const mappedUnsealedContainers = unsealedContainers.reduce((arr, container) => {
        const daysSinceArrival = moment().diff(moment(container.arrivalOnSite), 'd');

        arr[daysSinceArrival] += 1

        return arr;
    }, labels.map(() => 0));

    return (
        <Card elevation={3} className={classes.container} onClick={onModuleClick}>
            <CardActionArea className={classes.actionArea}>
                <Typography className={classes.title}>
                    {t('modules.chassisTimeLapseSinceArrival')}
                </Typography>
                <Bar
                    options={{
                        ...barOptions,
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem: any[]) {
                                    return t("modules.days", { days: tooltipItem[0]["index"]});
                                },
                            },
                        },
                    }}
                    data={{
                        labels,
                        datasets: [
                            {
                                label: t("modules.sealed"),
                                stack: t("modules.sealed"),
                                backgroundColor: '#60B690',
                                data: mappedSealedContainers,
                            },
                            {
                                label: t("modules.notSealed"),
                                stack: t("modules.sealed"),
                                backgroundColor: '#1C9195',
                                data: mappedUnsealedContainers,
                            },
                        ]
                    }} />
                    <Typography className={classes.xAxisTitle}>
                        {t("modules.downTime")}
                    </Typography>
            </CardActionArea>
        </Card>
    )
}

export default withStyles(style)(
    withTranslation()(ChassisTimeLapseSinceArrival)
);
