import {User} from "../session";

/* -----------------EVENT TEMPLATE TYPES----------------- */
export enum EventType {
    GATE_IN = "GATE_IN",
    GATE_OUT = "GATE_OUT",
    SEALING = "SEALING",
    INSPECTION = "INSPECTION",
    STUFFING = "STUFFING",
    UNSEALING = "UNSEALING",
    WEIGHTING = "WEIGHTING",
    OTHER = "OTHER",
}

export const eventTypes = [
    EventType.GATE_IN,
    EventType.GATE_OUT,
    EventType.SEALING,
    EventType.INSPECTION,
    EventType.STUFFING,
    EventType.UNSEALING,
    EventType.WEIGHTING,
    EventType.OTHER,
];
/* ------------------------------------------------------ */

/* -----------------FEATURE TEMPLATE TYPES----------------- */
export enum FeatureType {
    TRUCK_NUMBER = "TRUCK_NUMBER",
    CHASSIS_NUMBER = "CHASSIS_NUMBER",
    SEAL_NUMBER = "SEAL_NUMBER",
    LOCATION = "LOCATION",
    BOOKING_NUMBER = "BOOKING_NUMBER",
    SIGNATURE = "SIGNATURE",
    TEXT_FIELD = "TEXT_FIELD",
    CHECK_LIST = "CHECK_LIST",
    BOOLEAN = "BOOLEAN",
    IMAGE = "IMAGE",
}

export const featuresTypes = [
    FeatureType.TRUCK_NUMBER,
    FeatureType.CHASSIS_NUMBER,
    FeatureType.SEAL_NUMBER,
    FeatureType.LOCATION,
    FeatureType.BOOKING_NUMBER,
    FeatureType.SIGNATURE,
    FeatureType.TEXT_FIELD,
    FeatureType.CHECK_LIST,
    FeatureType.BOOLEAN,
    FeatureType.IMAGE,
];
/* -------------------------------------------------------- */

export const createNewFeature = (type: FeatureType): FeatureTemplate => ({
    label: "",
    type,
    ocr: false,
    qrcode: false,
    options: [],
});

export interface FeatureTemplate {
    id?: string;
    label: string;
    type: FeatureType;
    ocr: boolean;
    qrcode: boolean;
    options: string[];
}

export interface EventTemplate {
    id: string;
    label: string;
    type: EventType;
    position: number;
    features: FeatureTemplate[];
    attributions: User[];
}
