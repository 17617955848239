import { createStyles } from "@material-ui/core";

const containersStillOnSite = () => createStyles({
    container: {
        borderRadius: 10,
        height: '100%',
    },
    actionArea: {
        borderRadius: 10,
        height: '100%',
        padding: '20px 0px',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
        margin: '0 28px 20px',
    },
    xAxisTitle: {
        color: '#828A8C',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 500,
        marginLeft: 30,
    },
});

export default containersStillOnSite;
