import axios, {AxiosRequestConfig} from "axios";

const newAxios = axios.create({
		baseURL: process.env.REACT_APP_API_HOST!,
})

export const updateRequestHeader = (token?: string) => {
		newAxios.interceptors.request.use((config) => {
				if (token) {
						config.headers.Authorization = token;
				}

				return config;
		});
}

export const APIRoutes = {
		currentUser: (): AxiosRequestConfig => ({ method: "get", url: "/users/manager/me" }),

		/* EVENTS REQUESTS */
		eventTemplates: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/event-templates` }),
		updateEventTemplate: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "patch", url: `/sites/${siteId}/event-templates/${eventId}` }),
		createEventTemplate: (siteId: string): AxiosRequestConfig => ({ method: "post", url: `/sites/${siteId}/event-templates` }),
		deleteEventTemplate: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "delete", url: `/sites/${siteId}/event-templates/${eventId}` }),
		addFeature: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "post", url: `/sites/${siteId}/event-templates/${eventId}/features` }),
		updateFeature: (siteId: string, eventId: string, featureId: string): AxiosRequestConfig => ({ method: "patch", url: `/sites/${siteId}/event-templates/${eventId}/features/${featureId}` }),
		removeFeature: (siteId: string, eventId: string, featureId: string): AxiosRequestConfig => ({ method: "delete", url: `/sites/${siteId}/event-templates/${eventId}/features/${featureId}` }),
		addAttribution: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "post", url: `/sites/${siteId}/event-templates/${eventId}/attributions` }),
		removeAttribution: (siteId: string, eventId: string, userId: string): AxiosRequestConfig => ({ method: "delete", url: `/sites/${siteId}/event-templates/${eventId}/attributions/${userId}` }),
		/* --------------- */

		/* SITE PERMISSIONS */
		sitePermissions: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/permissions` }),
		addSitePermission: (siteId: string): AxiosRequestConfig => ({ method: "post", url: `/sites/${siteId}/permissions` }),
		removeSitePermission: (siteId: string, userId: string): AxiosRequestConfig => ({ method: "delete", url: `/sites/${siteId}/permissions/${userId}` }),
		/* ---------------- */

		/* STATISTICS */
		containersStillOnSite: (): AxiosRequestConfig => ({ method: "get", url: `/containers-still-on-site/statistic` }),
		/* ---------- */

		/* CONTAINERS */
		containers: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/containers` }),
		/* ---------- */
};

export default newAxios;
